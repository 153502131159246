import Bugsnag from '@bugsnag/js';
import { H } from 'highlight.run';
import ReactGA from 'react-ga4';

import { TrackingEvent } from './track';

const isDev = !window.config?.releaseStage || window.config?.releaseStage === 'development';

const init = () => {
  if (isDev) return;

  Bugsnag.start({
    apiKey: '9064c55366bf48da5795d5e4f2291541',
    enabledReleaseStages: ['production', 'staging', 'demo'],
    appVersion: window.config?.appVersion,
    releaseStage: window.config?.releaseStage,
    onError(event) {
      if (event.errors[0].errorMessage === 'Failed to fetch') {
        // usually this is connectivity - if there's a backend error,
        // we'll get an error report for that
        return false;
      }

      if (event.errors[0].errorMessage.includes('AbortError')) {
        // User aborted request
        return false;
      }
      return true;
    },
  });

  H.init('1ep96l7d', {
    serviceName: 'frontend-app',
    tracingOrigins: true,
    environment: window.config?.releaseStage,
    version: window.config?.appVersion,
    enableCanvasRecording: true, // enable canvas recording
    samplingStrategy: {
      canvas: 2, // snapshot at 2 fps
      canvasMaxSnapshotDimension: 480, // snapshot at a max 480p resolution
    },
    networkRecording: {
      enabled: true,
      recordHeadersAndBody: true,
      urlBlocklist: [
        // insert full or partial urls that you don't want to record here
        // Out of the box, Highlight will not record these URLs (they can be safely removed):
        'https://www.googleapis.com/identitytoolkit',
        'https://securetoken.googleapis.com',
        'https://bam.eu-1.nr-data.net',
      ],
    },
  });

  if (window.config?.currentUser?.email) {
    H.identify(window.config.currentUser.email, {
      id: window.config.currentUser.id || 'undefined user ID',
      name: window.config.currentUser.name,
      email: window.config.currentUser.email,
      role: window.config.currentUser.role,
      isDexory: window.config.currentUser.isDexory,
    });
  }

  const clarityRole = window.config?.currentUser?.isDexory ? 'staff' : 'customer';

  window.clarity?.('identify', window.config?.currentUser?.email);
  window.clarity?.('set', 'role', clarityRole);

  try {
    ReactGA.initialize('G-Q9248KMHN5', {
      gaOptions: {
        debug_mode: isDev,
      },
      gtagOptions: {
        debug_mode: isDev,
      },
    });
  } catch (e) {
    if (e instanceof Error) {
      Bugsnag.notify(e);
    }
  }
};

const trackEvent = (tracking: TrackingEvent) => {
  if (isDev) return;

  try {
    ReactGA.event({
      category: tracking.category,
      action: tracking.event,
      label: tracking.label,
      value: tracking.value,
      // nonInteraction: true, // optional, true/false
      // transport: 'xhr', // optional, beacon/xhr/image
    });
  } catch (e) {
    if (e instanceof Error) {
      Bugsnag.notify(e);
    }
  }
};

const trackPage = (page: string) => {
  if (isDev) return;

  try {
    ReactGA.send({ hitType: 'pageview', page });
  } catch (e) {
    if (e instanceof Error) {
      Bugsnag.notify(e);
    }
  }
};

export default {
  init,
  trackEvent,
  trackPage,
};
